import currency from "currency.js";
import { useQuery } from "react-query";
import { useNavigate, Outlet } from "react-router-dom";
import {
  FileListIcon,
  ArrowDownGrowthIcon,
  ArrowUpGrowthIcon,
  LineChartIcon,
  BucketIcon,
  PageHeaderListIcon,
  CurrencyFillIcon,
} from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { useIsAdmin, useIsCashier, useIsSales } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";
import { formatForQty } from "../../utils/helpers";
import { useEffect } from "react";
import { toast } from "react-toastify";

export function WarehouseWrapper({ Nav }) {
  const { backendUrl, user } = useAuth();
  const isAdmin = useIsAdmin();
  // const isCashier = useIsCashier();
  // const isSales = useIsSales();

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  let navigate = useNavigate();

  const getStats = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/warehouse/stats/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      //  credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let data = await response.json();

    return data;
  };

  const {
    error,
    data = { totalInventory: [], warehouseUsers: [], warehouse: {} },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    ["ITEMS_WAREHOUSE", { wareshouseLocal }],
    () => getStats(wareshouseLocal?.whID),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  const checkUsers = () => {
    const users = data?.warehouseUsers?.map((el) => el?.Staff_ID);
    if (!users.includes(user?.Staff_ID) && !isAdmin) {
      toast.error(
        `You are not registered to ${
          data?.warehouse?.W_name
        },  Please contact admin.`
      );
      navigate("/warehouse/warehouse");
    }
  };

  useEffect(() => {
    if (data.warehouseUsers?.length > 0) {
      checkUsers();
    }
  }, [data.warehouseUsers]);

  return (
    <div className="invoice-wrapper ">
      {/* <section className={`stats mb-3 ${isSales ? "two-column" : ""} `}> */}
      <section className={`stats mb-3 two-column `}>
        <div className="stat">
          <div className="icon bg-light-blue">
            <BucketIcon />
          </div>
          <div className="details">
            <p>
              {currency(
                data?.totalInventory?.length > 0
                  ? data?.totalInventory[0]?.Quantity
                  : 0,
                {
                  symbol: "",
                  precision: 2,
                  format: formatForQty,
                }
              ).format()}
            </p>
            <span>Total Inventory</span>
          </div>
        </div>

        {/* {!isSales ? (
          <> */}
        <div className="stat">
          <div className="icon bg-light-blue">
            <PageHeaderListIcon />
          </div>
          <div className="details">
            <p>
              {currency(
                data?.totalInventory?.length > 0
                  ? data?.totalInventory[0]?.valueInStock
                  : 0,
                {
                  symbol: "",
                }
              ).format()}
            </p>
            <span>Value In Stock</span>
          </div>
        </div>

        {/* <div className="stat">
              <div className="icon bg-light-blue">
                <CurrencyFillIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.estimatedValueInStock, {
                    symbol: "",
                  }).format()}
                </p>
                <span>Expected Sales Value</span>
              </div>
            </div> */}
        {/* </>
        ) : null} */}

        {/* <Link to="/supply-center" className="stat">
          <div className="icon bg-light-blue">
            <LineChartIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.inventoryShipped, {
                symbol: "",
               precision: 2,
 format: formatForQty,
              }).format()}
              %
            </p>
            <span>Inventory Shipped</span>
          </div>
        </Link> */}
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}
