import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MessageIcon,
  ExcelIcon,
  PDFIcon,
  EditIcon,
  ViewIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import SetSalesRepModal from "../SalesAndInvoicing/SetSalesRepModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { IsPrivileged } from "../DisplayChildElement";
import ChartofAccountReceivables from "../Maintenance/ChartofAccountReceivables";

export default function EmployeeAdvances() {
  useScrollTop();
  const reportTypeOptions = [
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Detailed",
      label: "Detailed",
    },
  ];
  const initialFilterParams = {
    customerLastname: "",
    customerFirstname: "",
  };

  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showSalesRepManager, setShowSalesRepManager] = useState(null);
  const [
    totalDebtFromOtherAccountRecievables,
    setTotalDebtFromOtherAccountRecievables,
  ] = useState(0);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchRecievables = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/accounts-recievables?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.manufacturers = data.manufacturers.map((el) => {
      const value = !["None"].includes(el?.InvoiceCat) ? el.InvoiceCat : "";
      return {
        label: el?.InvoiceCat,
        value,
      };
    });
    return data;
  };

  const {
    data = {
      count: 0,
      recievables: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.ACCOUNT_RECIEVABLES, queryParams],
    () => fetchRecievables(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/accounts-recievables?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    const manufacturer = exData.data.debtorsCat.map((d, i) => [
      i + 1,
      customerFullName(d?.DebtorsCat_Customers),
      d.InvoiceCat,
      currency(d.TotalDebt, {
        symbol: "",
        precision: 2,
      }).format(),
    ]);
    const nonManufacturer = exData.data.recievables.map((d, i) => [
      i + 1,
      d.Cust_ID,
      d.Customer,
      d.PhoneNo1,
      currency(d.TotalDebt, {
        symbol: "",
        precision: 2,
      }).format(),
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = queryParams?.manufacturer ? manufacturer : nonManufacturer;

    exData = [
      [company],
      ["Account Receivables Report"],
      [date],
      [""],
      queryParams?.manufacturer
        ? ["S/N", "Business Name", "Manufaturer", "Total Debt"]
        : ["S/N", "Cust ID", "Business Name", "Phone No", "Total Debt"],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Debt",
        currency(data.totalDebt, {
          symbol: "",
        }).format(),
      ],
      ["Credit Count", 0],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      Cust_ID: customer.Cust_ID,
      customerLastname: customer.LastName,
      customerFirstname: customer.FirstName,
    });
    setShowItemsPopover(false);
  };

  const manageSalesRep = (el) => {
    setSelectedCustomer(el);
    setShowSalesRepManager(true);
  };

  return (
    <IsPrivileged roleName="Accounts Receivables">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <ChartofAccountReceivables
                setTotalDebtFromOtherAccountRecievables={
                  setTotalDebtFromOtherAccountRecievables
                }
              />

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.employeeAdvancesDebit, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Debit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.employeeAdvancesCredit, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Credit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.balanceForEmployeeAdvances, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Balance (LCY)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showSalesRepManager && selectedCustomer ? (
          <SetSalesRepModal
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            setShowSaleRepModal={setShowSalesRepManager}
          />
        ) : null}
        <ModalLoader show={isfetchingExcel} />
      </main>
    </IsPrivileged>
  );
}
