import { useMemo, useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { services, durationOptions, appSettings } from "../../config";
import useDebounce, {
  useEffectOnce,
  useIsAdmin,
  useUpdateEffect,
} from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useEffect } from "react";
import {
  calculateMarkUp_Incentive_Margin,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  tonsToPcs,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useAuth } from "../../hooks/useAuth";
import { lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { truncate } from "lodash";
import SelectBatchDialog from "./SelectBatchDialog";
import { isEmpty } from "lodash";

export default function EditIronRodItemModal({
  selectedItemToEdit,
  setEditedItemIndex,
  handleEditItem,
  saleTypes = [],
  currencySymbol = "",
  itemsApiUrl = "/api/items",
  usage = "sale", // selector -
  mode,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl } = useAuth();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const isAdmin = useIsAdmin();
  const [unitPriceInTons, setUnitPriceInTons] = useState(0);
  const [unitPriceInPcs, setUnitPriceInPcs] = useState(0);
  const [selectedItem, setselectedItem] = useState(selectedItemToEdit);

  const formik = useFormik({
    initialValues: {
      Quantity: 1,
      UnitPrice: "",
      PriceSold: "",
      durationNumber: 0,
      durationValue: "Month",
      Warranty: false,
      Discount: 0,
      ironRodQuantity: 0,
      saleType: "",
      requirePermission: false,
      Size: "",
      remark: "",
      serialNumberItems: [],
      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
    },
    validationSchema: yup.object().shape({
      ironRodQuantity: yup
        .number()
        .required("required")
        .moreThan(0, "must be greater than 0"),
      UnitPrice: yup.number().required(),
      PriceSold: yup.number().required(),
    }),
    onSubmit: async (values) => {
      // console.log(values, selectedItem);

      // ----------------------

      /* if (values.saleType === "tons") {
        const quantity = tonsToPcs(values.Quantity, selectedItem.Item_Desc);
        if (Number(quantity) > selectedItem?.quantityInStock) {
          return formik.setFieldError(
            "ironRodQuantity",
            `Not enough item in stock`
          );
        }
      } else { */

      if (lowerCase(selectedItem.Item_Type) !== "service") {
        if (Number(values.Quantity) > selectedItem?.quantityInStock) {
          return formik.setFieldError(
            "ironRodQuantity",
            `Not enough item in stock`
          );
        }

        // check if they have enough sizes in stock
        if (values.Size && appSettings.hasSize) {
          const selectedSize = selectedItem.sizes.find(
            (el) => el.Size_Color === values.Size
          );
          if (Number(values.Quantity) > selectedSize.Quantity) {
            return formik.setFieldError(
              "Size",
              `Not enough item in stock for the selected Size - ${values.Size}`
            );
          }
        }
      }

      // }

      // if [ton, others ] or pcs
      const theUnitPrice =
        lowerCase(values.saleType) === "tons" ||
        lowerCase(values.saleType) !== "pieces"
          ? unitPriceInTons
          : unitPriceInPcs;

      if (
        Number(theUnitPrice) > Number(values.PriceSold) &&
        !appSettings.ignoreSalePermissions
      ) {
        if (
          !(await ConfirmDialog({
            description:
              "Selling Price entered will require permission \n Do you wish to continue",
          }))
        ) {
          return;
        } else {
          values.requirePermission = true;
        }
      }

      //if (values.Warranty) {
      values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`;
      /*  } else {
        values.Warrant_Duration = " ";
      } */

      /*  selectedItem.UnitCost = currency(selectedItem.UnitCost, {
        symbol: "",
        separator: "",
      }).format(); */

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).format();

      // console.log(values.Quantity);

      values.defaultSubTotal = currency(values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      // Get Profit Based on batch we are picking from---------------------------------------------------
      const quantityToSell = values.Quantity;
      let remainder = quantityToSell;
      const updatedBatchDataToSave = [];
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );

      if (
        lowerCase(values.saleType) !== "tons" &&
        lowerCase(values.saleType) !== "pieces"
      ) {
        measurement = {
          Quantity: 1,
        };
      }

      if (lowerCase(values.saleType) === "tons" && !measurement) {
        return toast.error("This item is not sold in Tons");
      }

      const priceSoldPerUnit =
        lowerCase(values.saleType) === "tons"
          ? currency(values.PriceSold)
              .divide(measurement?.Quantity || 1)
              .format()
          : values.PriceSold;

      //Set if can selected Batch
      let selectedBatches = [];
      if (generalSettings.selectBatchDuringInvoicing && usage === "sale") {
        const { batches } = await SelectBatchDialog({
          batches: selectedItem?.batches,
          quantityToSell: values.Quantity,
          generalSettings,
          previouslySelectedBatches: selectedItem?.selectedBatches || [],
        });
        selectedBatches = batches;
      }

      for (let [index, batch] of (!isEmpty(selectedBatches)
        ? selectedBatches
        : selectedItem.batches
      ).entries()) {
        const pcsUnitCost = currency(batch.UnitCost, {
          symbol: "",
          separator: "",
        })
          .divide(measurement?.Quantity || 1)
          .format();

        //
        const {
          calculatedMarkUp,
          calculatedIncentive,
        } = calculateMarkUp_Incentive_Margin({
          ...selectedItem,
          UnitCost: pcsUnitCost,
          PriceSold: priceSoldPerUnit,
          /*  Margin,
          marginType,
          marginPercentage,
          Incentive,
          incentiveType,
          incentivePercentage,
          markUp,
          markUpType,
          markUpPercentage, */
        });

        if (Number(batch.Quantity) >= Number(remainder)) {
          // means we are at the last
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: remainder,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalMargin: currency(selectedItem?.Margin || 0)
              .divide(measurement?.Quantity || 1)
              .multiply(remainder).value,
            totalIncentive: currency(calculatedIncentive || 0)
              .divide(measurement?.Quantity || 1)
              .multiply(remainder).value,
            totalMarkUp: currency(calculatedMarkUp || 0).multiply(remainder)
              .value,
          });

          if (lowerCase(values.saleType) === "pieces") {
            values.UnitCost = pcsUnitCost;
          }
          break;
        } else {
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: batch.Quantity,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalMargin: currency(selectedItem?.Margin || 0).multiply(
              batch.Quantity
            ).value,
            totalIncentive: currency(calculatedIncentive || 0).multiply(
              batch.Quantity
            ).value,
            totalMarkUp: currency(calculatedMarkUp || 0).multiply(
              batch.Quantity
            ).value,
          });

          remainder = Number(
            currency(remainder, {
              symbol: "",
              separator: "",
            })
              .subtract(batch.Quantity)
              .format()
          );
        }
      }

      const totalProfit = updatedBatchDataToSave
        .map(
          (el) =>
            currency(el.totalUnitPrice)
              .subtract(el.totalUnitCost)
              .subtract(el.totalIncentive).value
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b).value,
          0
        );
      values.Profit = totalProfit;
      values.totalUnitCost = updatedBatchDataToSave
        .map((el) => el.totalUnitCost)
        .reduce((a, b) => currency(a).add(b).value, 0);
      //-------------------------------------------------------------------------------------------------

      /*  values.Profit = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .subtract(selectedItem.UnitCost)
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format(); */

      values.Total_Incentive = updatedBatchDataToSave
        .map((el) => el.totalIncentive)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b).value,
          0
        );

      //  For Service Overwrite ----------------------------------------------------------
      if (lowerCase(selectedItem.Item_Type) === "service") {
        values.Profit = currency(priceSoldPerUnit)
          .subtract(selectedItem.UnitCost)
          .multiply(values.Quantity).value;
        values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
          values.Quantity
        ).value;
      }

      handleEditItem({
        ...selectedItem,
        Serial_Number: selectedItem.Item_Desc,
        ...values,
        selectedBatches,
        UnitCost: !isEmpty(selectedBatches)
          ? selectedBatches[0].UnitCost
          : selectedItem.UnitCost,
      });
    },
  });

  const getBatches = async (selectedItemFromPopover) => {
    /* 
      if we are editing an invoice  we need to get batches that was sold before
    */
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let response = await fetch(
          `${backendUrl}${itemsApiUrl}/batches-for-sales/${
            selectedItemFromPopover.Bar_Code
          }`,
          {
            method: "GET",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          response = await response.json();
          throw new Error();
        } else {
          let {
            data: { batches, sizes },
          } = await response.json();

          // --------------------------------------------------------------------
          if (mode === "edit" && !isEmpty(selectedItem.soldBatches)) {
            batches = [
              ...batches.map((el) => {
                // check if batch was sold before
                const foundSoldBatches = selectedItem.soldBatches.filter(
                  (soldbatch) => soldbatch.Bar_Code === el.Bar_Code
                );

                if (!isEmpty(foundSoldBatches)) {
                  const totalSoldQty = foundSoldBatches
                    .map((el) => el.Quantity)
                    .reduce((a, b) => currency(a).add(b).value, 0);
                  el.Quantity = currency(totalSoldQty).add(el.Quantity).value;
                }

                return el;
              }),
            ];
          }
          //---------------------------------------------------------

          selectedItem.batches = batches;
          resolve();
          // handleSelectedItem({ ...selectedItemFromPopover, batches, sizes });
        }
      } catch (err) {
        console.log(err);
        toast.error("Unable to load batches, Try again");
        reject();
      } finally {
        setIsLoading(false);
      }
    });
  };

  useEffectOnce(() => {
    console.log("once time -", selectedItem);
    if (
      isEmpty(selectedItem.batches) &&
      lowerCase(selectedItem.Item_Type) !== "service"
    ) {
      getBatches(selectedItem);
    }

    const UnitPrice = currency(selectedItem.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();
    const PriceSold = currency(selectedItem.PriceSold, {
      symbol: "",
      separator: "",
    }).format();
    const Quantity = currency(selectedItem.Quantity, {
      symbol: "",
      separator: "",
      precision: 2,
    }).format();

    const ironRodQuantity =
      lowerCase(selectedItem.saleType) === "tons"
        ? pcsToTons(Quantity, selectedItem.Item_Desc, itemMeasurements)
        : Quantity;

    const Discount = currency(selectedItem.Discount, {
      symbol: "",
      separator: "",
    })
      .divide(ironRodQuantity)
      .format();

    // For iron rod
    formik.setValues({
      ...formik.values,
      Quantity,
      UnitPrice,
      PriceSold: PriceSold,
      ...(Number(Discount) ? { Discount } : { Discount: "" }),
      ironRodQuantity:
        lowerCase(selectedItem.saleType) === "tons"
          ? pcsToTons(Quantity, selectedItem.Item_Desc, itemMeasurements)
          : Quantity,
      saleType: selectedItem.saleType,
      requirePermission: selectedItem.requirePermission,
      Size: selectedItem?.Serial_Number,
      remark: selectedItem?.remark,
      serialNumberItems: selectedItem.serialNumberItems,
    });

    if (selectedItem.Warranty) {
      const duration = selectedItem.Warrant_Duration.split(" ");

      formik.setFieldValue("Warranty", true);
      formik.setFieldValue("durationNumber", duration[0]);
      formik.setFieldValue("durationValue", duration[1]);
    }

    setUnitPriceInTons(selectedItem.UnitPrice);
    setUnitPriceInPcs(
      selectedItem.Tax ? selectedItem.Tax : selectedItem.UnitPrice
    );

    setTimeout(() => {
      const el = document.querySelector('input[name="ironRodQuantity"]');
      if (el) {
        el.focus();
        el.select();
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  });

  const handleDiscountChange = (discount) => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
    formik.setFieldValue("Discount", discount);
  };

  const handleSaleType = (value) => {
    // console.log(value);
    if (lowerCase(value) === "tons") {
      formik.setFieldValue("UnitPrice", unitPriceInTons);
      formik.setFieldValue("PriceSold", unitPriceInTons);
    } else {
      formik.setFieldValue("UnitPrice", unitPriceInPcs);
      formik.setFieldValue("PriceSold", unitPriceInPcs);
    }
  };

  // set quantity
  useEffect(() => {
    //if (selectedItem?.Item_Desc) {
    const quantity =
      lowerCase(formik.values.saleType) === "tons" && selectedItem?.Item_Desc
        ? tonsToPcs(
            formik.values.ironRodQuantity,
            selectedItem?.Item_Desc,
            itemMeasurements
          )
        : formik.values.ironRodQuantity;
    console.log(quantity);
    formik.setFieldValue("Quantity", quantity);
    // }
  }, [
    formik.values.ironRodQuantity,
    formik.values.saleType,
    selectedItem?.Item_Desc,
  ]);

  const sizes = useMemo(() => {
    return selectedItem && selectedItem?.sizes
      ? selectedItem?.sizes?.map((el) => ({
          ...el,
          label: `${el.Size_Color} - ${el.Quantity}`,
          value: el.Size_Color,
        }))
      : [];
  }, [selectedItem]);

  return (
    <Modal
      show={true}
      onHide={() => setEditedItemIndex(null)}
      dialogClassName="item-select-modal edit"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Edit Item Details</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-content">
          <div className="postion-relative">
            <FormikProvider value={formik}>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3 row">
                  <Form.Group className="mb-2 col-4">
                    <Form.Label className="slim">Item Name</Form.Label>
                    <p className="selected">
                      {selectedItem?.Item_Name || "..."}{" "}
                    </p>
                  </Form.Group>

                  <div className="col text-nowrap">
                    <Form.Label className="slim">Item Code</Form.Label>
                    <p className="selected">
                      {" "}
                      {selectedItem?.Bar_Code || "..."}
                    </p>
                  </div>

                  <div className="col-4">
                    <Form.Label className="slim">Size/Desc</Form.Label>
                    <p className="selected">
                      {truncate(
                        selectedItem?.Item_Desc || selectedItem?.Product_Model
                      )}
                    </p>
                  </div>

                  {selectedItem?.quantityInStock && (
                    <div className="col">
                      <Form.Label className="slim">
                        Quantity in Stock
                      </Form.Label>
                      <p className="selected">
                        {selectedItem?.quantityInStock
                          ? qtyFormatToString(
                              qtyFormat(
                                selectedItem?.quantityInStock,
                                selectedItem.Item_Desc,
                                itemMeasurements
                              )
                            )
                          : "..."}
                      </p>
                    </div>
                  )}

                  <div className="col-4">
                    {isAdmin || !generalSettings.hideUnitCost ? (
                      <>
                        <Form.Label className="slim">Cost Price</Form.Label>
                        <p className="selected">
                          {currency(selectedItem?.UnitCost, {
                            symbol: "",
                          }).format() || "..."}
                        </p>{" "}
                      </>
                    ) : null}
                  </div>
                </Form.Group>
                <hr />
                {/*  */}

                <Form.Group className="mb-3 row">
                  <Form.Group className="mb-2 col-4">
                    <Form.Label className="slim">
                      Supply Center Quantity
                    </Form.Label>
                    <p className="selected">
                      {selectedItem?.salesAnalysis
                        ? qtyFormatToString(
                            qtyFormat(
                              selectedItem?.salesAnalysis,
                              selectedItem.Item_Desc,
                              itemMeasurements
                            )
                          )
                        : "..."}
                    </p>
                  </Form.Group>

                  <div className="col text-nowrap">
                    <Form.Label className="slim">
                      Pre-order <br /> Quantity
                    </Form.Label>
                    <p className="selected">
                      {selectedItem?.preOrder
                        ? qtyFormatToString(
                            qtyFormat(
                              selectedItem?.preOrder,
                              selectedItem.Item_Desc,
                              itemMeasurements
                            )
                          )
                        : "..."}
                    </p>
                  </div>

                  <div className="col-4">
                    <Form.Label className="slim">
                      Approved Proforma Quantity
                    </Form.Label>
                    <p className="selected">
                      {selectedItem?.proforma
                        ? qtyFormatToString(
                            qtyFormat(
                              selectedItem?.proforma,
                              selectedItem.Item_Desc,
                              itemMeasurements
                            )
                          )
                        : "..."}
                    </p>
                  </div>

                  <div className="col">
                    <Form.Label className="slim">Available Quantity</Form.Label>
                    <p className="selected">
                      {selectedItem?.availableQty
                        ? qtyFormatToString(
                            qtyFormat(
                              selectedItem?.availableQty,
                              selectedItem.Item_Desc,
                              itemMeasurements
                            )
                          )
                        : "..."}
                    </p>
                  </div>
                </Form.Group>

                <hr />

                {/*  */}
                {usage === "sale" ? (
                  <>
                    <h2 className="pt-0">Pricing Details</h2>
                    {isAdmin || !generalSettings.hideUnitPrice ? (
                      <Form.Group className="mb-3 pb-1">
                        <Form.Label>Unit Price</Form.Label>
                        <CurrencyCustomInput
                          name="UnitPrice"
                          value={formik.values.UnitPrice}
                          isInvalid={
                            formik.touched.UnitPrice &&
                            !!formik.errors.UnitPrice
                          }
                          onValueChange={(value, name) => {
                            // formik.setFieldValue(name, value)
                          }}
                          placeholder="0.00"
                          decimalsLimit={2}
                        />
                        {formik.touched.UnitPrice &&
                        !!formik.errors.UnitPrice ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.UnitPrice}
                          </span>
                        ) : null}
                      </Form.Group>
                    ) : null}

                    {isAdmin || !generalSettings.hideDiscount ? (
                      <Form.Group className="mb-3 pb-1">
                        <Form.Label>
                          Discount <span className="slim">(optional)</span>
                        </Form.Label>
                        <CurrencyCustomInput
                          name="Discount"
                          value={formik.values.Discount}
                          onValueChange={(value, name) =>
                            handleDiscountChange(value)
                          }
                          placeholder="0.00"
                          decimalsLimit={2}
                        />
                      </Form.Group>
                    ) : null}

                    <Form.Group className="mb-3 pb-1">
                      <Form.Label>Final Selling price</Form.Label>
                      <CurrencyCustomInput
                        name="PriceSold"
                        placeholder="0.00"
                        decimalsLimit={2}
                        value={formik.values.PriceSold}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value)
                        }
                        disabled={
                          !isAdmin && generalSettings?.lockFinalSellingPrice
                        }
                      />
                      {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.PriceSold}
                        </span>
                      ) : null}
                    </Form.Group>
                  </>
                ) : null}

                <Form.Group className="mb-3 pb-2">
                  <Form.Label>Quantity</Form.Label>
                  <div className="duration">
                    <div>
                      <NumberCustomInput
                        placeholder="0"
                        name="ironRodQuantity"
                        value={formik.values.ironRodQuantity}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value, true);
                        }}
                        isInvalid={
                          formik.touched.ironRodQuantity &&
                          !!formik.errors.ironRodQuantity
                        }
                        onBlur={() =>
                          formik.setFieldTouched("ironRodQuantity", true)
                        }
                        allowNegativeValue={false}
                        disabled={!isEmpty(selectedItem?.serialNumberItems)}
                        allowDecimals={true}
                      />
                      {formik.touched.ironRodQuantity &&
                      !!formik.errors.ironRodQuantity ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.ironRodQuantity}
                        </span>
                      ) : null}
                    </div>

                    <Select
                      classNamePrefix={"form-select"}
                      placeholder={"Select"}
                      isSearchable={false}
                      value={saleTypes.find(
                        (el) => el.value === formik.values.saleType
                      )}
                      options={saleTypes}
                      onChange={({ value }) => {
                        formik.setFieldValue("saleType", value);
                        handleSaleType(value);
                      }}
                      isDisabled
                    />
                  </div>
                </Form.Group>

                {appSettings.hasSize ? (
                  <Form.Group className="mb-3 pb-1">
                    <Form.Label>Size / Color</Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      name="Size"
                      options={sizes}
                      value={sizes.find(
                        (el) => el.value === formik.values.Size
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Size", value);
                      }}
                      isDisabled={true}
                    />
                    {formik.touched.Size && !!formik.errors.Size ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Size}
                      </span>
                    ) : null}
                  </Form.Group>
                ) : null}

                {usage === "selector" ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        as={"textarea"}
                        name="remark"
                        placeholder="Enter Remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        rows={2}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <></>
                )}

                {/*  <Form.Check
                type="switch"
                id="custom-switch"
                label="Enable warranty"
                className="mb-3"
                name="Warranty"
                checked={formik.values.Warranty}
                onChange={formik.handleChange}
              /> */}

                {/*  <Form.Group className="mb-3 pb-2">
                <Form.Label>Set Duration</Form.Label>

                <div className="duration">
                  <NumberCustomInput
                    placeholder="0"
                    name="durationNumber"
                    value={formik.values.durationNumber}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                    // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                  />

                  <Select
                    classNamePrefix={"form-select"}
                    placeholder={"Month"}
                    value={durationOptions.find(
                      (el) => el.value === formik.values.durationValue
                    )}
                    options={durationOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("durationValue", value)
                    }
                  />
                </div>
              </Form.Group> */}

                {formik.values?.serialNumberItems && (
                  <>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Serial Numbers</Form.Label>

                      <FieldArray
                        name="serialNumberItems"
                        render={(arrayHelpers) => (
                          <>
                            <div className="row">
                              {formik.values.serialNumberItems?.map(
                                (el, index) => (
                                  <div className="col-11">
                                    <div className="d-flex gap-3 mb-2">
                                      <Form.Control
                                        name={`serialNumberItems[${index}].serialNum`}
                                        placeholder="Serial Number"
                                        value={
                                          formik.values.serialNumberItems[index]
                                            .serialNum
                                        }
                                        onChange={formik.handleChange}
                                      />
                                      <Button
                                        title="Remove"
                                        variant=""
                                        type="button"
                                        size="xs"
                                        onClick={() => {
                                          // Remove batch ---------------------------------
                                          const batchIndex = selectedItem.batches.findIndex(
                                            (batch) =>
                                              batch.Bar_Code ===
                                              formik.values.serialNumberItems[
                                                index
                                              ].BatchCode
                                          );
                                          const modifiedBatches =
                                            batchIndex !== -1
                                              ? [
                                                  ...selectedItem.batches.slice(
                                                    0,
                                                    batchIndex
                                                  ),
                                                  ...selectedItem.batches.slice(
                                                    batchIndex + 1
                                                  ),
                                                ]
                                              : [...selectedItem.batches];

                                          setselectedItem({
                                            ...selectedItem,
                                            batches: modifiedBatches,
                                          });

                                          arrayHelpers.remove(index);
                                          formik.setFieldValue(
                                            "ironRodQuantity",
                                            Number(
                                              formik.values.ironRodQuantity
                                            ) - 1,
                                            true
                                          );
                                        }}
                                      >
                                        ✖
                                      </Button>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>

                            <div>
                              <span className="custom-invalid-feedback">
                                {formik.errors.serialNumberItems}
                              </span>
                            </div>
                          </>
                        )}
                      />
                    </Form.Group>
                  </>
                )}

                <Button
                  disabled={!selectedItem || isLoading}
                  type="submit"
                  className="w-100 submit-btn"
                >
                  Save changes
                </Button>
              </Form>{" "}
            </FormikProvider>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
